//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SanghaImage',
  props: ['image', 'width', 'maxHeight', 'spinnerColor'],
  data() {
    return {
      imageData: null,
      observer: null,
      intersected: false,
      src: null,
      loaded: false,
      lazyImage: null,
    };
  },
  methods: {
    onIntersection(o) {
      if (o.isIntersecting || o.isVisible) this.lazyImage = this.image;
    },
  },
  computed: {
    // srcImage() {
    //   return this.intersected ? this.src : '';
    // },
  },
  mounted() {
    //
  },
};
